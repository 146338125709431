import logo from './logo.svg';
import './App.css';
import Routing from './components/Routing';

function App() {
  return (
    <Routing/>
  );
}

export default App;
